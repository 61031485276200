export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const USER_LOAD = "USER_LOAD";
export const LOG_OUT = "LOG_OUT";
export const GETPLAYERS_SUCCESS = "PLAYER_SUCCESS";
export const CHANGE_CURRENT_PAGE = "CHANGE_CURRENT_PAGE";
export const BOOKING_SUCCESS = "BOOKING_SUCCESS";
export const SET_BOOKING_DATE = "SET_BOOKING_DATE";
export const GET_BOOKING_DATA = "GET_BOOKING_DATA";
export const GET_USERS = "GET_USERS";
export const GET_CHATCONTENTS = "GET_CHATCONTENTS";
export const SET_SELECTEDUSERNAME = "SET_SELECTEDUSERNAME";
export const SAVE_CHATCONTENT = "SAVE_CHATCONTENT";
export const UPDATE_SEARCHINGPLAYER = "UPDATE_SEARCHINGPLAYER";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const GET_COMMENT = "GET_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const GET_PLAYERSCHEDULE = "GET_PLAYERSCHEDULE";
export const UPDATE_COURT_STATUS = "UPDATE_COURT_STATUS";
export const GET_COVEREDCALLPREMIUM = "GET_COVEREDCALLPREMIUM";
export const SYMBOL_NOT_FOUND_ERROR = "SYMBOL_NOT_FOUND_ERROR";
export const OPTIONS_NOT_FOUND_ERROR = "OPTIONS_NOT_FOUND_ERROR";
export const SERVICE_UNAVAILABLE_ERROR = "SERVICE_UNAVAILABLE_ERROR";
export const SERVER_ERROR = "SERVER_ERROR";
export const GET_EXPIRATIONS = "GET_EXPIRATIONS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const STRIPE_SUCCESS = "STRIPE_SUCCESS";
export const GET_STRIPE_STATUS = "GET_STRIPE_STATUS";
export const GET_PAYMENT_INTENT = "GET_PAYMENT_INTENT";
export const GET_CATEGORY = "GET_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const SET_SELECTED_SYMBOLS_FOR_CALCULATION =
  "SET_SELECTED_SYMBOLS_FOR_CALCULATION";
export const SET_SELECTED_SYMBOLS_FOR_PUT = "SET_SELECTED_SYMBOLS_FOR_PUT";
export const SET_OTM_PERCENTAGES = "SET_OTM_PERCENTAGES";
export const SET_WEEKLY_EXPIRY_DATES = "SET_WEEKLY_EXPIRY_DATES";
export const GET_CHECKOUT_SESSION_ID = "GET_CHECKOUT_SESSION_ID";
export const SET_CHECKOUT_SESSION_ID = "SET_CHECKOUT_SESSION_ID";
export const SET_GETTING_CHECKOUT_SESSION = "SET_GETTING_CHECKOUT_SESSION";
export const GET_SYMBOLLISTS = "GET_SYMBOLLISTS";
export const GET_BASIC_FILTER_RESULTS = "GET_BASIC_FILTER_RESULTS";
export const SET_SYMBOLS_FOR_OPTIONS_FILTER = "SET_SYMBOLS_FOR_OPTIONS_FILTER";
export const GET_OPTIONS_FILTER_RESULT = "GET_OPTIONS_FILTER_RESULT";
export const GET_STOCKS = "GET_STOCKS";
export const GET_SEARCH_FILTER_RESULTS = "GET_SEARCH_FILTER_RESULTS";
export const GET_CASH_SECURED_PUTS = "GET_CASH_SECURED_PUTS";
export const SET_OTM_ITM_PERCENTAGES = "SET_OTM_ITM_PERCENTAGES";
export const SET_FOUND_STOCK = "SET_FOUND_STOCK";
export const SET_FOUND_OPTION = "SET_FOUND_OPTION";
export const SET_SIDE = "SET_SIDE";
export const SET_CALL_CALCULATIONLIMIT = "SET_CALL_CALCULATIONLIMIT";
export const SET_PUT_CALCULATIONLIMIT = "SET_PUT_CALCULATIONLIMIT";
export const SET_SCREENER_CALCULATIONLIMIT = "SET_SCREENER_CALCULATIONLIMIT";

// For admin dashboard
export const GET_ANALYTICS_DATA = "GET_ANALYTICS_DATA";
export const GET_MARKET_STATUS = "GET_MARKET_STATUS";

// For profit horizon
export const GET_PROFIT_HORIZON = "GET_PROFIT_HORIZON";
export const SET_OTM_PERCENTAGES_FOR_PROFIT = "SET_OTM_PERCENTAGES_FOR_PROFIT";
export const SET_CLEAR_RESULT_FOR_PROFIT = "SET_CLEAR_RESULT_FOR_PROFIT"
